import * as React from 'react';
import firebase from "firebase";
import FileUploader from 'react-firebase-file-uploader';
import Form from 'react-bootstrap/Form';
import  noimage from '../../assets/images/no-image.jpg' 

export interface IImagesProps {
}

export interface IImagesState {
  name: string,
  page: string,
  image: string,
  isUploading: boolean,
  progress: number,
  imageURL: string
}

export default class Images extends React.Component<IImagesProps, IImagesState> {
  constructor(props: IImagesProps) {
    super(props);

    this.state = {
      name: 'abouttop',
      page: "about",
      image: "",
      isUploading: false,
      progress: 0,
      imageURL: ""
    }
  }

  handleChangePage = (event: any) => {
    firebase
      .storage()
      .ref("images/" + event.target.value + '/')
      .child(event.target.value + '.jpg')
      .getDownloadURL()
      .then(
        url =>
        this.setState({ 
          imageURL: url 
        },
          () => { console.log(url);
        })
      ).catch( error => {
          this.setState( {
            imageURL: 'https://firebasestorage.googleapis.com/v0/b/sadelmagerfyn-051281.appspot.com/o/images%2Fbg%2Fno-image.png?alt=media&token=c0edd28e-2e00-4272-af96-0cf45951e1cd'
          })
        });;
    this.setState({ name: event.target.value });
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = (progress: number) => this.setState({ progress });

  handleUploadError = (error: Error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = (filename: string) => {
    this.setState({ image: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images/" + this.state.name)
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ imageURL: url }));
  };

  public render() {
    return (
      <>
        <div className="container topSpacer">
          <h2 className='headerTitle'>Indsæt billede på en side.</h2>
          <form>
            <Form.Group className="uploadForm" controlId="pageSelect">
              <Form.Label>Vælg side</Form.Label>
              <Form.Control as="select" onChange={this.handleChangePage}>
                <option value='abouttop' >Om (øverst)</option>
                <option value='aboutbottom' >Om (nederst)</option>
{/*                 <option value='newstop'>Nyheder (øverst)</option>
                <option value='newsbottom'>Nyheder (nederst)</option>
                <option value='infotop'>Priser og Område (øverst)</option>
                <option value='infobottom'>Priser og Område (nederst)</option>*/}
                <option value='fittedSaddletop'>Specialtilpasset Sadel øverst</option>
                <option value='fittedSaddlebottom'>Specialtilpasset Sadel nederst</option> 
                <option value='otherServicestop'>Andre Lædervarer (øverst)</option>
                <option value='otherServicesbottom'>Andre Lædervarer (nederst)</option>
{/*                 <option value='shoptop'>Salg af Sadler (øverst)</option>
                <option value='shopbottom'>Salg af Sadler (nederst)</option>
                <option value='contacttop'>Kontakt (øverst)</option>
                <option value='contactbottom'>Kontakt (nederst)</option> */}
              </Form.Control>
            </Form.Group>
            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
            {this.state.imageURL && <img style={{maxWidth: '175px'}} src={this.state.imageURL} />}
            <label style={{ backgroundColor: 'steelblue', color: 'white', padding: 10, width: '175px', borderRadius: 4, cursor: 'pointer' }}>
              Vælg billede
            <FileUploader
                hidden
                accept="image/*"
                name="image"
                filename={(file: any) => this.state.name}
                storageRef={firebase.storage().ref("images/" + this.state.name)}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              /></label>
          </form>
        </div>
        
      </>
    );
  }
}
