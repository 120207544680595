export const SIGN_IN = "/signin";
export const HOME = "/";
export const ABOUT = "/About";
export const NEWS = "/News";
export const INFO = '/Info';
export const FITTED_SADDLE = '/FittedSaddle';
export const SHOP = '/Shop';
export const OTHER_SERVICES = '/OtherServices';
export const ADMIN = '/Admin';
export const CONTACT = '/Contact';
