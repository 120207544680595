import * as React from 'react';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import firebase from 'firebase';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html' 
import * as fb from '../fireBase/firebase'

export interface IInfoProps {
}

export interface IInfoState {
  topImagepath: string,
  buttomImagepath: string,
  buttomtext: any,
  toptext: any,
  model: string
}

const history = createBrowserHistory();
const db = fb.db;

export default class Info extends React.Component<IInfoProps, IInfoState> {
  constructor(props: IInfoProps) {
    super(props);

    this.state = {
      topImagepath: "",
      buttomImagepath: "",
      buttomtext: {},
      toptext: {},
      model: ""
    }
  }



  componentDidMount() {
    this.lookupdata();

  }

/*   lookupdata = () => {
    let ref = db.collection('prices').doc("prices");
   
    ref.get().then((doc) => {
        if (doc.exists) {
            console.log("Document data:", doc.data());
            let m = doc.data();
            this.setState({
                model: m!.model
            });
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

      
    });
  } */

  lookupdata = () => {
    let ref = db.collection('prices').doc("prices");

    ref.get().then((doc) => {
        if (doc.exists) {
            console.log("Document data:", doc.data());
            let m = doc.data();
            const convertedState = stateToHTML(convertFromRaw(m!.model));
            console.log("Converted Document data:", convertedState);
            
             this.setState({
                model: convertedState
            }); 
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }


    });
}


  createMarkup = () => {
    return {__html: this.state.model}
  }

  public render() {
    return (
      <>
        <Router history={history}>

          <div className="container, pagebg">
            <div className='fittedsaddleMain'>
              <h2 className='headerTitle'> Priser og Område</h2>
            </div>
              <div className="infoContents" dangerouslySetInnerHTML={this.createMarkup()} />
            
{/*                   <p>
                    <h5>Område</h5>
                    <p>Jeg kører på fyn og i jylland </p>
                    <br></br>
                    <h5>Konsultation :  600 kr. pr. hest</h5>
                    <h5>Kørsel 4 kr pr km.</h5>

                    <h5>Omstopning:  </h5>
                    <p>Fuld omstopning:  1800 Kr</p>
                    <p>Justering af stopning:   400 – 1500 kr</p>
                    <br></br>
                    <h5>Skifting af gjordstropper:</h5>
                    <p>Lange gjordstropper:  250 kr stk.</p>
                    <p>Originale Passier gjordstropper lange:  350 kr pr. stk.</p>
                    <p>Korte gjordstropper: 200 kr</p>
                    <p>Flytning af gjordstrop : 150 kr pr stk.</p>
                    <p>Hvis sadlen skal skilles ad for at få skiftet/flyttet gjordstropper tillægges et gebyr på 300 kr </p>
                    <br></br>
                    <h5>Bom Ændring:  1000 kr. </h5>
                    <p>Nye/større knæstøtter: 1600 kr.</p>
                    <p>shining af sadel ( dybdegående afrensning, om favning og læderpleje: 600 kr.  </p>
                    <br></br>
                    <h5>Håndsyede artikler efter mål:</h5>
                    <p>Rundsyet trense med tøjler:  5500 kr.   </p>
                    <p>Læder grime:  1500 kr.</p>
                    <p>Rundsyet kandar med tøjler: 9500 kr.</p>
                    <br></br>


                    <h5>Reparation af sadler, trenser m.m.:   Ring venligst for et prisoverslag.</h5>
                    <br></br>
                    <h5>Tasker, kernelæder møbler og div. andre lædergenstande repareres</h5>
                    <p>Ring venligst for prisoverslag</p>
                    <br></br>
                    <h5>Reparation af dækkener:</h5>

                    <p>Mindre reparationer udføres samt udskiftning af spænder m.m. 20 kr. pr 10 cm sygning på maskine. </p>
                    <p>Ring venligst for et prisoverslag.</p>

                    <p>Dækkener SKAL være rene når de afleveres til reparation. </p>
<br></br>
                    <h5>Andet læderarbejde udføres </h5>
                    <p>Ring venligst for prisoverslag</p>
                  </p>



                </div> */}
            
           
          </div>
        </Router>

      </>

    );
  }
}
