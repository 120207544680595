import * as React from 'react';
import firebase, { firestore, storage } from "firebase";
import { Saddle } from '../../Classes/Saddle';
import * as fb from '../fireBase/firebase'
import { Form, Row, Col, Container, Modal, Button} from 'react-bootstrap';
import FileUploader from 'react-firebase-file-uploader';
import imageCompression from 'browser-image-compression';
import { fileURLToPath } from 'url';
import * as fs from 'fs';
import { exif } from 'exif-parser';


export interface ISaddlesProps {
}

export interface ISaddlesState {
  errorMessage: string,
  setShow: boolean,
  image: string,
  imageURL: string,
  filenames: any[],
  downloadURLs: any[],
  isUploading: boolean,
  uploadProgress: number,
  name: string,
  saddles: Saddle[],
  saddle: Saddle,
  ImageToBeAddedIndex: number,
  statusmessage: string,
  delimg: any,
  delimIndex: number
}

const db = fb.db;


export default class Saddles extends React.Component<ISaddlesProps, ISaddlesState> {

  constructor(props: ISaddlesProps) {
    super(props);

    this.state = {
      filenames: [],
      downloadURLs: [],
      errorMessage: "",
      image: "",
      imageURL: "",
      uploadProgress: 0,
      isUploading: false,
      name: "",
      saddles: [],
      saddle: { id: "", name: "", size: "", brand: "", price: "", color: "", info: "", description: "", type: "", age: "", downloadURLs: [] },
      ImageToBeAddedIndex: 0,
      statusmessage: "",
      setShow: false,
      delimg: "",
      delimIndex: 0
    }
  }
  onTableChange = (type, newState) => {
    console.log('type ', type, ' newState ', newState);
    let tempstate = this.state.saddles;
    let i = tempstate.findIndex(x => x.id === newState.cellEdit.rowId);
    if (i !== -1) {
      console.log(newState);
      tempstate[i][newState.cellEdit.dataField] = newState.cellEdit.newValue;
      this.setState({ saddles: tempstate });
    }

    let saddleRef = db.collection('saddles').doc(newState.cellEdit.rowId)
    saddleRef.update(
      newState.cellEdit.dataField, newState.cellEdit.newValue
    );

    this.setState({
      ImageToBeAddedIndex: i
    })
  }

  deleteSaddle = (rowIndex: number) => {
    let tempSaddles = this.state.saddles;
    let targetSaddle = tempSaddles[rowIndex];
    let saddleId = tempSaddles[rowIndex].id;
    targetSaddle.downloadURLs.forEach(url => {
      firebase.storage().refFromURL(url).delete();
      firestore().collection('saddles').doc(saddleId).delete().then(doc => {
        console.log('sadel ', doc, 'er slettet')
      });
    });

    tempSaddles.splice(rowIndex, 1);
    this.setState({ saddles: tempSaddles });
    this.lookupdata();
  }

  saveSaddle = (rowIndex: number) => {
    let tempSaddles = this.state.saddles;
    let targetSaddle = tempSaddles[rowIndex];
    let saddleId = tempSaddles[rowIndex].id;
    /* targetSaddle.downloadURLs.forEach(url => {
      firestore().collection('saddles').doc(saddleId).update(targetSaddle).then(doc => {
        console.log('sadel ', doc, 'er slettet')
      });
    }); */
    let dbDoc = db.collection('saddles').doc(targetSaddle.id);
    let newSaddle = this.state.saddles[rowIndex];

    return dbDoc.set({
      age: newSaddle.age,
      brand: newSaddle.brand,
      color: newSaddle.color,
      description: newSaddle.description,
      info: newSaddle.info,
      downloadURLs: newSaddle.downloadURLs,
      name: newSaddle.name,
      price: newSaddle.price,
      size: newSaddle.size,
      type: newSaddle.type
    })
      .then( x => {
        console.log('updated', x);
        this.setState( {
          statusmessage: "Ændringerne er gemt."
        });
        setTimeout(() => {
          this.timeoutMessage();
        }, 3000);
        this.lookupdata();
      }
      )
      .catch(error => {
        console.log('notfound', error);
      })

  }



  editFormatter = (cell, row, rowIndex, formatExtraData) => {
    console.log('rowindex', rowIndex);
    return (
      <>
      <div className="btn btn-success" onClick={() => this.saveSaddle(rowIndex)} >Gem Sadel</div>
        <div className="btn btn-danger" onClick={() => this.deleteSaddle(rowIndex)} >Slet Sadel</div>
        <label style={{ backgroundColor: 'steelblue', color: 'white', padding: 10, width: '75px', borderRadius: 4, cursor: 'pointer' }}>
          Tilføj Billede
    <FileUploader
            hidden
            accept="image/*"
            name="image-uploader-multiple"
            randomizeFilename
            storageRef={firebase.storage().ref("images/saddles/")}
            onUploadStart={() => this.handleUploadStart(rowIndex)}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess2}
            onProgress={this.handleProgress}
            multiple
          /></label>
      </>
    );

  }

  imgFormatter = (cell, row, rowIndex, formatExtraData) => {
    const imgstyle = {
      width: '50px'
    }
    return (
      <>
        <div>
          <label> klik på et billede for at slette.</label>
          {this.state.saddles[rowIndex].downloadURLs.map((img, index) => {
           

            return (
              <img key={index} alt={this.state.saddles[rowIndex].name} src={img} style={imgstyle} onClick={() => this.querydeleteimage(img, rowIndex)}></img>
            );
          })}
        </div>
      </>
    )
  }

  idFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>{rowIndex}</div>
    );
  }

  querydeleteimage(img, rowIndex) {
    this.setState({
      delimIndex: rowIndex,
      delimg: img,
    })
    this.handleShow();
  }

  timeoutMessage = () => {
        this.setState({
          statusmessage: ""
        })
  }

  deleteimage = (imageUrl: string, rowindex: number) => {

    let saddleimageindex = this.state.saddles[rowindex].downloadURLs.findIndex(x => x === imageUrl);
    console.log('imageurlindex', saddleimageindex);
    let tempstate = Object.assign({}, this.state);
    tempstate.saddles[rowindex].downloadURLs.splice(saddleimageindex, 1);
    this.setState(tempstate);
    let saddleId = tempstate.saddles[rowindex].id;
    console.log('saddleId', saddleId);
    let saddleRef = db.collection('saddles').doc(saddleId)
    saddleRef.update({
      downloadURLs: firebase.firestore.FieldValue.arrayRemove(imageUrl)
    })
    let storageRef = firebase.storage().refFromURL(imageUrl);
    storageRef.delete().then(() => {
      console.log(imageUrl, " is deleted");
      this.setState( {
        statusmessage: "billedet er slettet."
      });
      setTimeout(() => {
        this.timeoutMessage();
      }, 3000);
    })
      .catch((error) => {
        console.log("deleteimageerror", error);
        this.setState({
          statusmessage: "Der er sket en fejl"
        });
        setTimeout(() => {
          this.timeoutMessage();
        }, 3000);
        
      });
  }



  componentDidMount() {
    this.lookupdata();

  }

  handleUploadStart = (index) => this.setState({
    filenames: [],
    downloadURLs: [],
    ImageToBeAddedIndex: index,
    isUploading: true,
    uploadProgress: 0
  });

  handleProgress = (uploadProgress: number) => this.setState({ uploadProgress });

  handleUploadError = (error: Error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = async filename => {
    const downloadURL = await firebase
      .storage()
      .ref("images/saddles")
      .child(filename)
      .getDownloadURL();


    this.setState(oldState => {
      return ({
        filenames: [...oldState.filenames, filename],
        downloadURLs: [...oldState.downloadURLs, downloadURL],
        uploadProgress: 100,
        isUploading: false
      });
    });

  };

  handleUploadSuccess2 = async (filename, index) => {
    console.log('filename', filename, 'index ', index);
    const downloadURL = await firebase
      .storage()
      .ref("images/saddles")
      .child(filename)
      .getDownloadURL();
    let tempSadddleState = this.state.saddles;
    tempSadddleState[this.state.ImageToBeAddedIndex].downloadURLs.push(downloadURL);
    this.setState({ saddles: tempSadddleState });
    let saddle = this.state.saddles[this.state.ImageToBeAddedIndex].id;
    let saddleRef = db.collection('saddles').doc(saddle)
    saddleRef.update({
      downloadURLs: firebase.firestore.FieldValue.arrayUnion(downloadURL)
    });
    this.setState(oldState => {
      return ({
        filenames: [...oldState.filenames, filename],
        downloadURLs: [...oldState.downloadURLs, downloadURL],
        uploadProgress: 100,
        isUploading: false,
        ImageToBeAddedIndex: 0
      });
    });
    this.lookupdata();

  };

  handleClose = () => {this.setState({setShow: false})};
  handleShow = () => {this.setState({setShow: true})};


  handleInputChange = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    let tempstate = Object.assign({}, this.state);
    tempstate.saddle[name] = value;
    tempstate.saddle['downloadURLs'] = this.state.downloadURLs;

    this.setState(tempstate);

    console.log('stateafterinput', tempstate);
  }

  lookupdata = () => {
    let ref = db.collection('saddles');
    let itemsarray: any = [];
    ref.get().then((docs) => {
      console.log('docs', docs);
      /* let itemsarray = docs.docs.map(doc => {doc.data();}); */
      docs.docs.map(doc => {
        let saddle = new Saddle(doc.id, doc.data().name, doc.data().size, doc.data().brand, doc.data().color, doc.data().info, doc.data().description, doc.data().price, doc.data().type, doc.data().age, doc.data().downloadURLs);
        itemsarray.push(saddle)
      });

      console.log('saddlesarray ', itemsarray);
      this.setState(
        {
          saddles: itemsarray
        }, () => { console.log('state after lookup', this.state.saddles) }

      );
    });
  }

  saddleGrid = () => {

    /* return (
     
    ); */
  }

  handleInputChange2 = (saddle, index) => {
    let tempSaddles = this.state.saddles;
    let newValue = saddle.currentTarget.value;
    tempSaddles[index][saddle.currentTarget.name] = newValue;
    this.setState({saddles: tempSaddles});
  }

  addSaddle = () => {
    let tempstate = Object.assign({}, this.state);
    tempstate.saddle['downloadURLs'] = this.state.downloadURLs;
    let ref = db.collection('saddles');
    ref.add(tempstate.saddle).then(x => {
      console.log('updated', x);
      this.setState({
        statusmessage: "Sadel er tilføjet."
      }); 
      setTimeout(() => {
        this.timeoutMessage();
      }, 3000);
      
      this.lookupdata();
    })
  }


  public render() {
    const rowStyle = { width: '50px' };

    const columns = [
      {
        dataField: 'id',
        text: 'id',
        formatter: this.idFormatter
      },
      {
        dataField: 'name',
        text: 'navn'
      },
      {
        dataField: 'brand',
        text: 'Mærke'
      },
      {
        dataField: 'price',
        text: 'Pris'
      },
      {
        dataField: 'color',
        text: 'Farve'
      },
      {
        dataField: 'size',
        text: 'Størrelse'
      },
      {
        dataField: 'info',
        text: 'Info'
      },
      {
        dataField: 'description',
        text: 'Beskrivelse'
      },
      {
        dataField: 'age',
        text: 'alder'
      },
      {
        dataField: 'type',
        text: 'Type'
      },
      {
        dataField: 'downloadURLs',
        text: 'Billeder',
        isDummyField: true,
        formatter: this.imgFormatter,
        editable: false
      },
      {
        dataField: 'edit',
        text: 'handlinger',
        isDummyField: true,
        formatter: this.editFormatter,
        editable: false
      }
    ]
    const imgaddstyle = {
      width: '200px',
    }

    return (
      <div className="container topSpacer">
        <h2 className='headerTitle'>Opret sadler</h2>

        <form>
          <Form.Group className="uploadText" controlId="textChange">
            <Form.Label>Navn</Form.Label>
            <Form.Control onChange={this.handleInputChange} as="input" name="name" value={this.state.saddle.name} placeholder="Navn" />
            <Form.Label>Pris</Form.Label>
            <Form.Control onChange={this.handleInputChange} as="input" name="price" value={this.state.saddle.price} placeholder="Pris" />
            <Form.Label>Størrelse</Form.Label>
            <Form.Control onChange={this.handleInputChange} as="input" name="size" value={this.state.saddle.size} placeholder="Størrelse" />
            <Form.Label>Type</Form.Label>
            <Form.Control onChange={this.handleInputChange} as="input" name="type" value={this.state.saddle.type} placeholder="Type" />
            <Form.Label>Mærke</Form.Label>
            <Form.Control onChange={this.handleInputChange} as="input" name="brand" value={this.state.saddle.brand} placeholder="Mærke" />
            <Form.Label>Alder</Form.Label>
            <Form.Control onChange={this.handleInputChange} as="input" name="age" value={this.state.saddle.age} placeholder="Alder" />
            <Form.Label>Farve</Form.Label>
            <Form.Control onChange={this.handleInputChange} as="input" name="color" value={this.state.saddle.color} placeholder="Farve" />
            <Form.Label>Info</Form.Label>
            <Form.Control onChange={this.handleInputChange} as="input" name="info" value={this.state.saddle.info} placeholder="Info" />
            <Form.Label>Beskrivelse</Form.Label>
            <Form.Control onChange={this.handleInputChange} as="input" name="description" value={this.state.saddle.description} placeholder="Beskrivelse" />
            {this.state.isUploading && <p>Progress: {this.state.uploadProgress}</p>}
            {this.state.downloadURLs && <div>
              {this.state.downloadURLs.map((downloadURL, i) => {
                return <img style={imgaddstyle} key={i} src={downloadURL} />;
              })}
            </div>}
            <label style={{ backgroundColor: 'steelblue', color: 'white', padding: 10, width: '175px', borderRadius: 4, cursor: 'pointer' }}>
              Vælg billeder
            <FileUploader
                hidden
                accept="image/*"
                name="image-uploader-multiple"
                randomizeFilename
                storageRef={firebase.storage().ref("images/saddles/")}
                onUploadStart={() => this.handleUploadStart(0)}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
                multiple
              /></label>
            <label>{this.state.errorMessage}</label>
          </Form.Group>
        </form>
        <div className='btn btn-info' onClick={this.addSaddle} >Tilføj ny Sadel.</div>
        <hr />

        <Row>
          <Col>
            <div>test</div>
            <div></div>
          </Col>
        </Row>
       {/*  {<BootstrapTable
          wrapperClasses="table-responsive"
          keyField='id'
          data={this.state.saddles}
          columns={columns}
          bootstrap4={true}
          rowStyle={rowStyle}
          remote={true}
          onTableChange={this.onTableChange}
          cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
        />} */}
        
        <Container>
        {this.state.saddles.map((saddle: Saddle, index) => {
          return(
          <Row key={index} id={saddle.id} className="SaddleGrid">
            <Col lg='2' className="SaddleGridCol">
            <label>Navn</label>
            <textarea className="SaddleGridTextArea" name='name' value={this.state.saddles[index].name} onChange={(saddle) => this.handleInputChange2(saddle, index)} />
            </Col>
            <Col lg='2' className="SaddleGridCol">
            <label>Mærke</label>
            <textarea className="SaddleGridTextArea" name='brand' value={this.state.saddles[index].brand} onChange={(saddle) => this.handleInputChange2(saddle, index)} />
            </Col>
            <Col lg='2' className="SaddleGridCol">
            <label>Pris</label>
            <textarea className="SaddleGridTextArea" name='price' value={this.state.saddles[index].price} onChange={(saddle) => this.handleInputChange2(saddle, index)} />
            </Col>
            <Col lg='2' className="SaddleGridCol">
            <label>Farve</label>
            <textarea className="SaddleGridTextArea" name='color' value={this.state.saddles[index].color} onChange={(saddle) => this.handleInputChange2(saddle, index)} />
           </Col>
            <Col lg='2' className="SaddleGridCol">
            <label>Størrelse</label>
            <textarea className="SaddleGridTextArea" name='size' value={this.state.saddles[index].size} onChange={(saddle) => this.handleInputChange2(saddle, index)} />
            </Col>
            <Col lg='2' className="SaddleGridCol">
            <label>Info</label>
              <textarea className="SaddleGridTextArea" name='info' value={this.state.saddles[index].info} onChange={(saddle) => this.handleInputChange2(saddle, index)} />
            </Col>
            <Col lg='2' className="SaddleGridCol">
            <label>Beskrivelse</label>
            <textarea className="SaddleGridTextArea" name='description' value={this.state.saddles[index].description} onChange={(saddle) => this.handleInputChange2(saddle, index)} />
            </Col>
            <Col lg='2' className="SaddleGridCol">
            <label>alder</label>
            <textarea className="SaddleGridTextArea" name='age' value={this.state.saddles[index].age} onChange={(saddle) => this.handleInputChange2(saddle, index)} />
            </Col>
            <Col lg='2' className="SaddleGridCol">
            <label>Type</label>
            <textarea className="SaddleGridTextArea" name='type' value={this.state.saddles[index].type} onChange={(saddle) => this.handleInputChange2(saddle, index)} />
            </Col>
            <Col lg='2' className="SaddleGridCol">
              {this.imgFormatter(0, 0, index, null)}
            </Col>
            <Col lg='2' className="SaddleGridCol">
              {this.editFormatter(0,0,index,null)}
            </Col>
            {this.state.statusmessage}
          </Row>);
        })}

      </Container>
      <Modal show={this.state.setShow} onHide={this.handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div>
            Er du sikker på du vil slette billedet
            
            {this.state.statusmessage}
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={() => this.deleteimage(this.state.delimg, this.state.delimIndex)}>Slet billede</Button>
          <Button variant="secondary" onClick={this.handleClose}>
            Luk
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
      
    );
  }

}
