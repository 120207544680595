import * as React from "react";
import { auth } from "../fireBase";

interface InterfaceProps {
  email?: string;
  error?: any;
  history?: any;
  password?: string;
}

interface InterfaceState {
  email: string;
  error: any;
  password: string;
}

export default class SignInForm extends React.Component<
  InterfaceProps,
  InterfaceState
  > {
  private static INITIAL_STATE = {
    email: "",
    error: null,
    password: ""
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  constructor(props: InterfaceProps) {
    super(props);

    this.state = { ...SignInForm.INITIAL_STATE };
  }

  public onSubmit = (event: any) => {
    const { email, password } = this.state;

    const { history } = this.props;

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...SignInForm.INITIAL_STATE }));
        /*  history.push(routes.ADMIN); */
      })
      .catch((error: any) => {
        this.setState(SignInForm.propKey("error", error));
      });

    event.preventDefault();
  };

  public render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <div className="signinForm">
        <h2>Log på</h2>
        <form onSubmit={event => this.onSubmit(event)}>
          <input
            value={email}
            onChange={event => this.setStateWithEvent(event, "email")}
            type="text"
            placeholder="Email Adresse"
          />
          <input
            value={password}
            onChange={event => this.setStateWithEvent(event, "password")}
            type="password"
            placeholder="Password"
          />
          <button disabled={isInvalid} type="submit">
            Log På
        </button>

          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(SignInForm.propKey(columnType, (event.target as any).value));
  }
}