import * as React from 'react';
import { Navbar, Nav } from "react-bootstrap";
import { Link } from 'react-router-dom';
import * as routes from './Constants/routes';
import { AuthUserContext } from './Components/fireBase/AuthUserContext';
import { auth } from './Components/fireBase';

export const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser => {
      return (authUser ? <NavigationAuth /> : <NavigationNonAuth />);
    }}
  </AuthUserContext.Consumer>
);

const NavigationAuth = () => (
  <>
    <Navbar collapseOnSelect expand="xl" >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" >
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto row">
          <Nav.Link  eventKey="1" as={Link} to={routes.ADMIN}>Admin</Nav.Link>
           <Nav.Link eventKey="2" as={Link} to={routes.ABOUT}>Hjem</Nav.Link>
           {/* <Nav.Link eventKey="3" as={Link} to={routes.ABOUT}>Om</Nav.Link> */}
           <Nav.Link eventKey="4" as={Link} to={routes.NEWS}>Nyheder</Nav.Link>
           <Nav.Link eventKey="5" as={Link} to={routes.INFO}>Priser og Område</Nav.Link>
           <Nav.Link eventKey="6" as={Link} to={routes.FITTED_SADDLE}>Specialtilpasset Sadel</Nav.Link>
           <Nav.Link eventKey="7" as={Link} to={routes.OTHER_SERVICES}>Andre Lædervarer</Nav.Link>
           <Nav.Link eventKey="8" as={Link} to={routes.SHOP}>Salg af Sadler</Nav.Link>
           <Nav.Link eventKey="9" as={Link} to={routes.CONTACT}>Kontakt</Nav.Link>
          <button className="navlink col-lg-1" type="button" onClick={auth.doSignOut}> Sign Out </button>
        </Nav>
      </Navbar.Collapse>
      
    </Navbar>

  </>
);

const NavigationNonAuth = () => (
  <>
    <Navbar collapseOnSelect expand="lg" >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" >
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
           <Nav.Link eventKey="1" as={Link} to={routes.ABOUT}>Hjem</Nav.Link>
           {/* <Nav.Link eventKey="2" as={Link} to={routes.ABOUT}>Om</Nav.Link> */}
           <Nav.Link eventKey="3" as={Link} to={routes.NEWS}>Nyheder</Nav.Link>
           <Nav.Link eventKey="4" as={Link} to={routes.INFO}>Priser og Område</Nav.Link>
           <Nav.Link eventKey="5" as={Link} to={routes.FITTED_SADDLE}>Specialtilpasset Sadel</Nav.Link>
           <Nav.Link eventKey="6" as={Link} to={routes.OTHER_SERVICES}>Andre Lædervarer</Nav.Link>
           <Nav.Link eventKey="7" as={Link} to={routes.SHOP}>Salg af Sadler</Nav.Link>
           <Nav.Link eventKey="8" as={Link} to={routes.CONTACT}>Kontakt</Nav.Link>
           <Nav.Link eventKey="9" as={Link} to={routes.SIGN_IN}>Log ind</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </>
);