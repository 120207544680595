
import * as React from "react";
import { AuthUserContext } from '../fireBase/AuthUserContext';
import { withAuthorization } from '../../Components/fireBase/withAuthorization';
import Images from "../Images/Images";
import { Redirect } from "react-router";
import Texts from "../../Components/texts";
import Saddles from "../Saddles/Saddles";
import { Accordion, Button, Card } from "react-bootstrap";
import NewsItems from "./newsItems";
import AdminPrices from "./adminPrices";
export const AdminComponent = () => (


  <AuthUserContext.Consumer>
  {authUser => (
    authUser === null ?
      <Redirect to={'\home'}  ></Redirect>
    :
    <div>
      {/* <h1>Account: {(authUser as any).email}</h1> */}
      <Accordion>
      <Accordion.Toggle as={Card.Header}  eventKey="0">
        Billeder
       </Accordion.Toggle>
       <Accordion.Collapse eventKey="0">
       <Images></Images>
       </Accordion.Collapse>
      <Accordion.Toggle as={Card.Header} eventKey="1">
        Tekster
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
      <Texts></Texts>
      </Accordion.Collapse>
      <Accordion.Toggle as={Card.Header} eventKey="2">
        Sadler
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
      <Saddles></Saddles>
      </Accordion.Collapse>
      <Accordion.Toggle as={Card.Header} eventKey="3">
        Nyheder
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="3">
      <NewsItems></NewsItems>
      </Accordion.Collapse>
      <Accordion.Toggle as={Card.Header} eventKey="4">
        Priser
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="4">
      <AdminPrices></AdminPrices>
      </Accordion.Collapse>
      </Accordion>
      
    </div>
  ) }
</AuthUserContext.Consumer>

);

const authCondition = (authUser: any) => !!authUser;
export const Admin = withAuthorization(authCondition)(AdminComponent);