import * as React from 'react';


export interface IContactProps {
}

export interface IContactState {
}

export default class Contact extends React.Component<IContactProps, IContactState> {
  constructor(props: IContactProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className="container, pagebg">
        <div className='aboutMain'>
          <h2 className='headerTitle'> Kontakt</h2>
          {/* <Row>
            <Col sm={{span: 3}} ><img className="logofooter" src={logo} alt="Logo"></img></Col>
            <Col sm={{span: 3}} >Saddelmager  <br/> Kathrine Bøg Holm. <br/>Hersnap bygade 10 <br/>5380 Dalby</Col>
            <Col sm={{span: 3}} ><p>Tlf: 51747512</p></Col>
            <Col sm={{span: 3}} ><a href="mailto:bholm0512@hotmail.com">E-mail: bholm0512@hotmail.com</a></Col>
          </Row> */}
          

                               



        </div>
        <iframe width="95%" height="450" src="https://www.google.com/maps/embed/v1/place?q=Hersnap%20Bygade%2010%20%2C%20Dalby%2C%20Danmark&key=AIzaSyBpN4GmFg0QCmSjyNToXJFXQGB5OARLTZE" ></iframe>
      </div>

    );
  }
}
