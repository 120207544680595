import * as React from 'react';
import firebase from 'firebase';

export interface IBackGroundImageProps {
}

export interface IBackGroundImageState {
    bg:string,
    x: any,
    y: any
}

export default class BackGroundImage extends React.Component<IBackGroundImageProps, IBackGroundImageState> {
  constructor(props: IBackGroundImageProps) {
    super(props);

    this.state = {
        bg: "",
        x: "",
        y: ""
    }
  }

  componentWillMount = () => {
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;
    
    firebase
      .storage()
      .ref("images/bg/bg3.jpg")
      .getDownloadURL()
      .then(url => this.setState({ bg: url }));

    this.setState({x:x,y:y});
  }

  public render() {
    return (<div><img className='bg' src={this.state.bg} /></div>)
  }
}
