import * as React from 'react';
import logo from '../../assets/images/logo50.jpg';
import mslogo from '../../assets/images/mslogo.png';
export interface IHeaderProps {
}

export interface IHeaderState {
}
export default class Header extends React.Component<IHeaderProps, IHeaderState> {
    constructor(props: IHeaderProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <>
                <header className="App-header">
                    <div className="row">
                        <div className="logo col-sm-2">
                            <img className="logoleft" src={logo} alt="Logo"></img>

                        </div>
                        <div className="logodevice col-sm-3">
                            <img className="logotopright" src={mslogo} alt="Logo"></img>

                        </div>
                        
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 headerTitle">
                                <div className="titlebackground"> 1</div>
                                <div className="row"></div>
                                <div className="col-12"><h2>Sadelmager</h2></div>
                                <div className="col-12"><h2>Kathrine Bøg Holm</h2></div>
                            </div>
                        
                        <div className="logolarge col-sm-2">
                            <img className="logoright" src={mslogo} alt="Logo"></img>

                        </div>
                    </div>
                </header>
            </>
        );
    }
}
