import * as React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import * as fb from '../fireBase/firebase'
import { NewsItem } from '../../Classes/NewsItem';
import firebase from 'firebase';
import { Form, FormControl, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { GalleryImage } from '../../Classes/GalleryImage';

export interface INewsItemsProps {
}

export interface INewsItemsState {
  newz: NewsItem[],
  filenames: string[],
  downloadURLs: string[],
  errorMessage: string,
  image: string,
  imageURL: string,
  uploadProgress: number,
  isUploading: boolean,
  name: string,
  ImageToBeAddedIndex: number,
  text: string,
  newsItem: NewsItem,
  heading: string
}

const db = fb.db;

export default class NewsItems extends React.Component<INewsItemsProps, INewsItemsState> {
  constructor(props: INewsItemsProps) {
    super(props);

    this.state = {
      filenames: [],
      downloadURLs: [],
      errorMessage: "",
      image: "",
      imageURL: "",
      uploadProgress: 0,
      isUploading: false,
      name: "",
      ImageToBeAddedIndex: 0,
      newz: [],
      text: "",
      newsItem: new NewsItem("", new Date(), "", "", ""),
      heading: ""
    }
  }

  componentDidMount() {
    this.lookupdata();

  }

  lookupdata = () => {
    let ref = db.collection('news');
    let itemsarray: any = [];
    ref.get().then((docs) => {
      console.log('docs', docs);
      /* let itemsarray = docs.docs.map(doc => {doc.data();}); */
      docs.docs.map(doc => {
        let newsItem = new NewsItem(doc.id, doc.data().date, doc.data().text, doc.data().imageUrls, doc.data().heading);
        itemsarray.push(newsItem)
      });

      console.log('news ', itemsarray);
      this.setState(
        {
          newz: itemsarray
        }, () => { console.log('state after lookup', this.state.newz) }

      );
    });
  }

  handleChangeText = (event: any) => {
    this.setState({ text: event.target.value });
  }

  handleChangeHeading = (event: any) => {
    this.setState({ heading: event.target.value });
  }

  handleSubmitNews = (event: any) => {
    let tempstate = Object.assign({}, this.state);
    let galImages: GalleryImage[] = [];
    this.state.downloadURLs.forEach(url => {
      let galImg = new GalleryImage(url, url);
      galImages.push(galImg);
      tempstate.newsItem.imageUrls = JSON.stringify(galImages);
    });




    tempstate.newsItem.text = this.state.text;
    tempstate.newsItem.heading = this.state.heading;
    let ref = db.collection('news');
    let item = Object.assign({}, tempstate.newsItem);
    ref.add(item).then(x => {
      console.log('updated', x);
      this.setState({
        downloadURLs: []
      });
      this.lookupdata();
    });
  }

  handleUploadStart = () => this.setState({
    isUploading: true,
    uploadProgress: 0
  });

  handleProgress = (uploadProgress: number) => this.setState({ uploadProgress });

  handleUploadError = (error: Error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = async (filename, index) => {
    console.log('filename', filename, 'index ', index);
    const downloadURL = await firebase
      .storage()
      .ref("images/news")
      .child(filename)
      .getDownloadURL();
    /*  let tempNewzState = this.state.newz;
     if (tempNewzState.findIndex(index) !== -1 ) {
       tempNewzState[index].imageUrls.push(downloadURL);
       this.setState({ newz: tempNewzState });
       let newsItemID = this.state.newz[index].id;
       let newsRef = db.collection('news').doc(newsItemID)
       newsRef.update({
         downloadURLs: firebase.firestore.FieldValue.arrayUnion(downloadURL)
       }).then( x => {
         console.log('updated');
       }).catch(error => {
         console.log('notfound ');
       });
     } */

    this.setState(oldState => {
      return ({
        filenames: [...oldState.filenames, filename],
        downloadURLs: [...oldState.downloadURLs, downloadURL],
        uploadProgress: 100,
        isUploading: false,
        ImageToBeAddedIndex: 0
      });
    });

  };

  handleNewsInputChange = (text, index) => {
    let tempstatenews = this.state.newz;
    tempstatenews[index].text = text.currentTarget.value;
    this.setState({
      newz: tempstatenews
    })
    return;
  }

  handleNewsHeadlineInputChange = (heading, index) => {
    let tempstatenews = this.state.newz;
    tempstatenews[index].heading = heading.currentTarget.value;
    this.setState({
      newz: tempstatenews
    })
    return;
  }

  handleUpdateNewsItem = (index) => {
    let tempItems = this.state.newz;
    let targetItem = tempItems[index];
    let dbDoc = db.collection('news').doc(targetItem.id);
    let newItem = this.state.newz[index];

    return dbDoc.set({
      heading: newItem.heading,
      date: newItem.date,
      imageUrls: newItem.imageUrls,
      text: newItem.text,
      id: newItem.id
    })
      .then(x => {
        console.log('updated', x);
        this.lookupdata();
      }
      )
      .catch(error => {
        console.log('notfound', error);
      })
  }

  handleDeleteNewsItem = (index) => {
    let tempItems = this.state.newz;
    let targetItem = tempItems[index];
    let dbDoc = db.collection('news').doc(targetItem.id);
    let images = JSON.parse(targetItem.imageUrls);

    return dbDoc.delete().then(x => {
      console.log('deleted', x);
      images.forEach(img => {
        let storageRef = firebase.storage().refFromURL(img.original);
        storageRef.delete();
      });
      this.lookupdata();
    }
    )
      .catch(error => {
        console.log('error', error);
      });
  }

  handleDeleteImage = (newsIndex, imgindex) => {
    const newsItem = this.state.newz[newsIndex];
    const imageObj = JSON.parse(newsItem.imageUrls);
    let storageRef = firebase.storage().refFromURL(imageObj[imgindex].original);
    storageRef.delete().then(() => {
      console.log(imageObj[imgindex].original, 'deleted')
    }).catch((error) => {
      console.log('error', error);
      
      
    });;
    imageObj.splice(imgindex, 1);
    let dbDoc = db.collection('news').doc(newsItem.id);
    return (
      dbDoc.set({ 
        imageUrls: newsItem.imageUrls = JSON.stringify(imageObj),
        heading: newsItem.heading,
        date: newsItem.date,
        text: newsItem.text,
        id: newsItem.id 
      }));

  }


  public render() {
    const imgaddstyle = {
      width: '200px',
    }
    return (
      <div className="container topSpacer">
        <h2 className='headerTitle'>Opret Nyhed</h2>
        <Row>
          <Col xs="12" sm="12" md="6">
            <Accordion defaultActiveKey="0">
              {
                this.state.newz.map((item, index) => {
                  const timestamp = new Date(item.date['seconds'] * 1000);
                  const images = JSON.parse(item.imageUrls);
                  console.log('date', item.date['seconds'] * 1000)

                  var formattedTimestamp = Intl.DateTimeFormat('da-DK', {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                    hour: "numeric",
                    minute: "2-digit",
                    second: "2-digit"
                  }).format(timestamp);
                  return (


                    <Card key={index}>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                          {formattedTimestamp}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index.toString()}>
                        <Card.Body>
                          Overskrift: <textarea className="NewsHeaderTextArea" name='heading' value={item.heading} onChange={(newstext) => this.handleNewsHeadlineInputChange(newstext, index)} />
                          <Row>

                            <Col xs="12" sm="12" md="6">
                              {images.map((img, i) => {
                                return (
                                  <img key={i} onClick={() => this.handleDeleteImage(index, i)} width="100%" src={img.original} />
                                );
                              })}
                            </Col>
                            <Col xs="12" sm="12" md="6">
                              <textarea className="NewsTextArea" name='text' value={item.text} onChange={(newstext) => this.handleNewsInputChange(newstext, index)} />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6"><Button onClick={() => this.handleDeleteNewsItem(index)} variant="danger">Slet Nyhed</Button></Col>
                            <Col xs="6"><Button onClick={() => this.handleUpdateNewsItem(index)} variant="info">Gem Nyhed</Button></Col>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                  );
                })
              }
            </Accordion>
          </Col>
          <Col xs="12" sm="12" md="6">
            <div>
              <h3>Opret ny</h3>
              <label style={{ backgroundColor: 'steelblue', color: 'white', padding: 10, width: '175px', borderRadius: 4, cursor: 'pointer' }}>
                Vælg billede
            <FileUploader
                  hidden
                  accept="image/*"
                  name="image-uploader-multiple"
                  randomizeFilename
                  storageRef={firebase.storage().ref("images/news/")}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                  onProgress={this.handleProgress}
                  multiple
                /></label>
              <label>{this.state.errorMessage}</label>
              <form>
                <Form.Group className="uploadText" controlId="headingChange">
                  <Form.Label>
                    Indsæt/ændre Overskrift
            </Form.Label>
                  <FormControl
                    onChange={this.handleChangeHeading}
                    value={this.state.heading}
                    as="textarea"
                  />
                  {this.state.isUploading && <p>Progress: {this.state.uploadProgress}</p>}
                  {this.state.downloadURLs && <div>
                    {this.state.downloadURLs.map((downloadURL, i) => {
                      return <img style={imgaddstyle} key={i} src={downloadURL} />;
                    })} </div>
                  }
                </Form.Group>
                <Form.Group className="uploadText" controlId="textChange">
                  <Form.Label>
                    Indsæt/ændre tekst
            </Form.Label>
                  <FormControl
                    onChange={this.handleChangeText}
                    value={this.state.text}
                    as="textarea"
                  />

                </Form.Group>
              </form>
              <div className='btn btn-info' onClick={this.handleSubmitNews} >GEM</div>
            </div>
          </Col>
        </Row>



      </div>
    );
  }
}
