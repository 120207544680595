import * as React from 'react';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import firebase from 'firebase';
import * as fb from '../fireBase/firebase'

export interface IOtherServicesProps {
}

export interface IOtherServicesState {
  topImagepath: string,
  buttomImagepath: string,
  toptext: any,
  buttomtext: any
}

const history = createBrowserHistory();
const db = fb.db;

export default class OtherServices extends React.Component<IOtherServicesProps, IOtherServicesState> {
  constructor(props: IOtherServicesProps) {
    super(props);

    this.state = {
      topImagepath: "",
      buttomImagepath: "",
      buttomtext: {},
      toptext: {}
    }
  }

  componentDidMount() {
    let storage = firebase.storage();
    let topref = storage.ref('images/otherServicestop/otherServicestop.jpg');
    let buttomref = storage.ref('images/otherServicesbuttom/otherServicesbuttom.jpg');
    topref.getDownloadURL().then(
      img => {
        this.setState(
          { topImagepath: img }
        )
      }
    )
    .catch(error => {
      console.log(error);
      
    });



    buttomref.getDownloadURL().then(
      img => {
        this.setState(
          { buttomImagepath: img }
        )
      }
    )
    .catch(error => {
      console.log(error);
      
    });

    var docTopRef = db.collection("texts").doc("otherServicestop");

    docTopRef.get().then((doc) => {
    if (doc.exists) {
        console.log("Document data:", doc.data());;
        this.setState({toptext: doc.data()})
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
}).catch(function(error) {
    console.log("Error getting document:", error);
});

var docbuttomRef = db.collection("texts").doc("otherServicesbuttom");

docbuttomRef.get().then((doc) => {
if (doc.exists) {
    console.log("Document data:", doc.data());;
    this.setState({buttomtext: doc.data()})
} else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
}
}).catch(function(error) {
console.log("Error getting document:", error);
});
  }

  public render() {
    return (
      <>
        <Router history={history}>

          <div className="container, pagebg">
            <div className='otherServicesMain'>
              <h2 className='headerTitle'> Andre Lædervarer</h2>
            </div>

            <div className="row contentTop">
              <div className="col-xs-12">

              </div>
            </div>

            <div className="row contentUpperMiddle">
              <div className="col-xs-0 col-sm-0 col-md-2 col-lg-2  contentUpperMiddleMargin">

              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 contentUpperMiddlePhoto">
                <img className="otherServicesImage" src={this.state.topImagepath}></img>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 contentUpperMiddleText">
                <div className="textBlur"> 
                  {this.state.toptext.text}
                </div>
                
              </div>
              <div className="col-xs-0 col-sm-0 col-md-2 col-lg-2 contentUpperMiddleMargin">

              </div>
            </div>

            <div className="row contentLowerMiddle">
              <div className="col-xs-0 col-sm-0 col-md-2 col-lg-2 contentLowerMiddleMargin">

              </div>
              <div className="col-xs-12 col-sm-12 col-md-5 contentLowerMiddleText">

              <div className="textBlur">

                    {this.state.buttomtext.text}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3 contentLowerMiddlePhoto">
                <img className="otherServicesImage" src={this.state.buttomImagepath}></img>
              </div>
              <div className="col-xs-0 col-sm-0 col-md-2 col-lg-2 contentLowerMiddleMargin">

              </div>
            </div>

            <div className="row contentButtom">
              <div className="col-xs-12">

              </div>
            </div>

          </div>
        </Router>

      </>

    );
  }
}
