import { GalleryImage } from "./GalleryImage";

export class NewsItem {
    id: string;
    date: Date;
    text: string;
    imageUrls: string
    heading: string;

    constructor(id: string, date: Date, text: string, imageUrls: string, heading: string) {
        this.id = id;
        this.date = date;
        this.heading = heading
        this.text = text;
        this.imageUrls = imageUrls
    }
}