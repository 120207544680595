import * as React from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Header from './Components/Header/Header';
import { Navigation } from "./Navigation";
import * as routes from "./Constants/routes";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { withAuthentication } from './Components/fireBase/withAuthentication';
import Home from './Components/Home/Home';
import News from './Components/News/News';
import Shop from './Components/Shop/Shop';
import About from './Components/About/About';
import SignInForm from './Components/SignIn/Signin';
import Info from './Components/Info/Info';
import FittedSaddlePage from './Components/FittedSaddle/FittedSaddle';
import { AdminComponent } from './Components/Admin/Index';
import firebase from 'firebase';
import './assets/EdwardianScriptITC.ttf'
import OtherServices from './Components/OtherServices/OtherServices';
import BackGroundImage from './Components/backgroundImage';
import Contact from './Components/Contact/Index';
import Footer from './Components/Footer';

interface IState {
  bg: string,
  authUser: any,
  filename: string
}

class AppComponent extends React.Component<{}, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      authUser: null,
      filename: '',
      bg: ''
    };
  }

  public componentDidMount() {
    firebase.auth().onAuthStateChanged(authUser => {
      authUser
        ? this.setState(() => ({ authUser }))
        : this.setState(() => ({ authUser: null }));
    });
    firebase
      .storage()
      .ref("images/bg/bg3.jpg")
      .getDownloadURL()
      .then(url => this.setState({ bg: url }));
  }

  public render() {
    const bgpath = {
      backgroundImage: 'url(' + this.state.bg + ')',
      backgroundSize: 'cover',
      minHeight: '1000px'     
    }
    return (
      <>
      <BackGroundImage></BackGroundImage>
      <div className="main container"  /* style={bgpath} */ >
        
        <div className="row">
          
        </div>
        <div>
          <BrowserRouter >
          <Navigation />
          <Header></Header>
            <Switch>
              <Route exact path="/" component={About} />
              <Route path={routes.ADMIN} component={AdminComponent} />
              <Route path="/news" component={News} />
              <Route path="/about" component={About} />
              <Route path="/info" component={Info} />
              <Route path='/fittedSaddle' component={FittedSaddlePage} />
              <Route path='/otherServices' component={OtherServices} />
              <Route path="/shop" component={Shop} />
              <Route path={routes.CONTACT} component={Contact}/>
              <Route path="/signin" component={SignInForm} />
            </Switch>
          </BrowserRouter>
          <footer><Footer></Footer></footer>
        </div>

        <div></div>
      </div>
</>
    );
  }
}
export const App = withAuthentication(AppComponent);
