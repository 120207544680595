import * as React from 'react';
import Form from 'react-bootstrap/Form';
import * as fb from '../fireBase/firebase'
import { FormControl } from 'react-bootstrap';

export interface ITextsProps {
}

interface Text {
  page: string,
  text: string
}

export interface ITextsState {
    page: string,
    text: any,
    texts: any[]
}

const db = fb.db;

export default class Texts extends React.Component<ITextsProps, ITextsState> {
  constructor(props: ITextsProps) {
    super(props);

    this.state = {
        page: '',
        text: '',
        texts: []
    }
  }

  componentDidMount() {
    this.lookupdata();

  }

  lookupdata = () => {
    let ref = db.collection('texts');
    
    ref.get().then((docs) => {
      console.log('docs', docs);
      let textarray = docs.docs.map(doc => doc.data());
      
      console.log('textarray ', textarray);
      this.setState(
        {
          texts: textarray
        }
      )
    });
  }

  handleChangePage = (event: any) => {
    this.setState({ page: event.target.value });
    this.getTextForSpecificPage(event.target.value);
  }

  handleChangeText = (event: any) => {
    this.setState({ text: event.target.value });
    const updateId = this.state.texts.find(x => x.page === this.state.page);
    console.log(updateId);
    
  }

  getTextForSpecificPage = (page) => {
    if(this.state.texts.findIndex(el => el.page === page) !== -1){
      const t = this.state.texts.find(el => el.page === page);
      this.setState({ text: t.text });
    }
    else {
      this.setState({ text: ''})
    }
  }

  submitText =  (event: React.FormEvent) => {
    event.preventDefault();
    const t = this.state.texts.findIndex(el => el.page === this.state.page);
    let document = {
      'text': this.state.text,
      'page': this.state.page
    }

    let dbDoc = db.collection('texts').doc(this.state.page);
    return dbDoc.update({
      text: this.state.text
    })
      .then( x => {
        console.log('updated');
        this.lookupdata();
      }
      )
      .catch(error => {
        console.log('notfound create new');
        
        db.collection('texts').doc(this.state.page).set(document);
        this.lookupdata();
      })
    
  } 

  public render() {
    return (
      <div className="container topSpacer">
        <h2 className='headerTitle'>Tilføj / Ændre tekster</h2>
        <form>
          <Form.Group className="uploadTextForm" controlId="pageSelect">
            <Form.Label>Vælg side</Form.Label>
            <Form.Control as="select" onChange={this.handleChangePage}>
              <option value='abouttop' >Om (øverst)</option>
              <option value='aboutbuttom' >Om (nederst)</option>
              {/* <option value='infotop'>Priser og Område (øverst)</option>
              <option value='infobuttom'>Priser og Område (nederst)</option> */}
              <option value='fittedSaddletop'>Specialtilpasset Sadel øverst</option>
              <option value='fittedSaddlebuttom'>Specialtilpasset Sadel nederst</option>
              <option value='otherServicestop'>Andre Lædervarer (øverst)</option>
              <option value='otherServicesbuttom'>Andre Lædervarer (nederst)</option>
            </Form.Control>
          </Form.Group>
        </form>
        <form>
          <Form.Group className="uploadText" controlId="textChange">
            <Form.Label>
              Indsæt/ændre tekst
            </Form.Label>
            <FormControl
                onChange={this.handleChangeText}
                value={this.state.text}
                as="textarea"
            />
            
          </Form.Group>
        </form>
        <div className='btn btn-info' onClick={this.submitText} >GEM</div>
      </div>
    );
  }
}
