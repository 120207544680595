import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
import {config} from './firebaseConfig'


if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const auth = firebase.auth();
export const db = firebase.firestore();