export class Saddle {
    id: string;
    name: string;
    size: string;
    brand: string;
    color: string;
    info: string;
    description: string;
    price: string;
    type: string;
    age: string;
    downloadURLs: string[];

    constructor(id:string, name:string, size:string, brand:string, color:string, info: string, description:string, price:string, type:string, age:string, downloadURLs: string[]) {
        this.id = id;
        this.name = name;
        this.size = size;
        this.brand = brand;
        this.color = color;
        this.info = info;
        this.description = description;
        this.price = price;
        this.type = type;
        this.age = age;
        this.downloadURLs = downloadURLs;
    }
}