import * as React from 'react';
import ImageGallery from 'react-image-gallery';
import * as fb from '../fireBase/firebase'
import { NewsItem } from '../../Classes/NewsItem';
import { GalleryImage } from '../../Classes/GalleryImage';
import { Row, Col } from 'react-bootstrap';


export interface INewsProps {
}

export interface INewsState {
  images: GalleryImage[];
  newz: NewsItem[]
}

const db = fb.db;

export default class News extends React.Component<INewsProps, INewsState> {
  constructor(props: INewsProps) {
    super(props);

    this.state = {
      images: [],
      newz: []
    }
  }

  componentDidMount() {
    this.lookupdata();

  }

  lookupdata = async () => {
    let ref = db.collection('news').orderBy('date', 'desc');
    let itemsarray: any = [];
    ref.get().then((docs) => {
      docs.docs.map(doc => {
        let newsItem = new NewsItem(doc.id, doc.data().date, doc.data().text, doc.data().imageUrls, doc.data().heading);
        itemsarray.push(newsItem)
      });
      this.setState(
        {
          newz: itemsarray,
          images: JSON.parse(itemsarray[itemsarray.length - 1].imageUrls)
        }, () => { console.log('state after news lookup', this.state.newz, this.state.images) }

      );
    });
  }



  /* createAlbum = async() => {
    let images: GalleryImage[]; 
    this.state.newz[0].imageUrls.forEach(element => {
      let image = new GalleryImage(element, element)
      images.push(image);
      this.setState(
        {
          
         images: images 
        }, () => { console.log('state after lookup', this.state.newz, this.state.images) }
    
      );
  })
  
    }; */


  public render() {
    return (
      <>
        <div className="container topSpacer">
          <Row>
          <Col className="AboutMain">
          <h2 className='headerTitle'>Nyheder</h2>
          </Col>
          </Row>
          <Row>
          
            {
              this.state.newz.map((item, index) => {
                let date = new Date();

                const timestamp = new Date(item.date['seconds'] * 1000);
                console.log('date', item.date['seconds'] * 1000)

                var formattedTimestamp = Intl.DateTimeFormat('da-DK', {
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                  hour: "numeric",
                  minute: "2-digit",
                  second: "2-digit"
                }).format(timestamp);

                return (
                  <>
                  <Row key={index}>
                    <Col>
                      <h5>{item.heading}</h5>
                      <h6>{formattedTimestamp}</h6>
                    </Col>
                  </Row>
                    <Row>
                      <Col xs="12" sm="12" md="6">
                      <ImageGallery showPlayButton={false} showThumbnails={false} items={JSON.parse(item.imageUrls)} />
                      </Col>
                      <Col>
                      {item.text}
                      </Col>
                    </Row>
                   </>
                 
                );
              })
            }
          
          </Row>
        </div>
      </>
    );
  }
}
