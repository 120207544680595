import * as React from 'react';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Row, Col, Button } from 'react-bootstrap';

import * as fb from '../fireBase/firebase'

export interface IAdminPricesProps {
}

export interface IAdminPricesState {
    editorState: EditorState
}
const db = fb.db;

export default class AdminPrices extends React.Component<IAdminPricesProps, IAdminPricesState> {

    constructor(props: IAdminPricesProps) {
        super(props);

        this.state = {
            editorState: EditorState.createEmpty()
        }
    }

    componentDidMount() {
        this.lookupdata();

    }

    lookupdata = () => {
        let ref = db.collection('prices').doc("prices");

        ref.get().then((doc) => {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                let m = doc.data();
                const convertedState = convertFromRaw(m!.model);
                console.log("Converted Document data:", doc.data());
                const editorValue = EditorState.createWithContent(convertedState);
                 this.setState({
                    editorState: editorValue
                }); 
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }


        });
    }

    handleModelChange = (model) => {
        console.log(model);
        this.setState({
            editorState: model
        });
    }

    handleSavePage = () => {

        var convertedData = convertToRaw(this.state.editorState.getCurrentContent())
        let dbDoc = db.collection('prices').doc("prices");
        let pricemodel = convertedData;

        return dbDoc.set({
            model: pricemodel
        })
            .then(x => {
                console.log('updated', x);
                this.lookupdata();
            }
            )
            .catch(error => {
                console.log('notfound', error);
            })
    }

    public render() {
        return (
            <div className="container topSpacer">
                <h2 className='headerTitle'>Priser og område</h2>
                <Editor
                    editorState={this.state.editorState} wrapperClassName="demo-wrapper" editorClassName="editer-content"
                    onEditorStateChange={this.handleModelChange}
                />
                <Row>
                    <Col xs="6"><Button onClick={() => this.handleSavePage()} variant="info">Gem Nyhed</Button></Col>
                </Row>
            </div>
        );
    }
}
