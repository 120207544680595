import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import logo from '../../assets/images/logo.jpg';

export interface IFooterProps {
}

export interface IFooterState {
}

export default class Footer extends React.Component<IFooterProps, IFooterState> {
  constructor(props: IFooterProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
        <div className="container, pagebg">
        <div className='FooterMain'>
          <Row>
            <Col sm={{span: 3}} ><img className="logofooter" src={logo} alt="Logo"></img></Col>
            <Col sm={{span: 3}} >Saddelmager  <br/> Kathrine Bøg Holm. <br/>Hersnap bygade 10 <br/>5380 Dalby</Col>
            <Col sm={{span: 3}} ><p>Tlf: 51747512</p></Col>
            <Col sm={{span: 3}} ><a href="mailto:bholm0512@hotmail.com">E-mail: bholm0512@hotmail.com</a></Col>
          </Row>
          

                               



        </div>
      {/*   <iframe width="100%" height="450"  src="https://www.google.com/maps/embed/v1/place?q=Hersnap%20Bygade%2010%20%2C%20Dalby%2C%20Danmark&key=AIzaSyBpN4GmFg0QCmSjyNToXJFXQGB5OARLTZE" ></iframe> */}
      </div>
      </div>
    );
  }
}
