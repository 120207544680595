import * as React from 'react';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import firebase from 'firebase';
import * as fb from '../fireBase/firebase'
import LatestNews from '../News/LatestNews';
export interface IAboutProps {
}

export interface IAboutState {
  topImagepath: string,
  buttomImagepath: string,
  toptext: any,
  buttomtext: any
}
const history = createBrowserHistory();
const db = fb.db;
export default class About extends React.Component<IAboutProps, IAboutState> {
  constructor(props: IAboutProps) {
    super(props);

    this.state = {
      topImagepath: "",
      buttomImagepath: "",
      buttomtext: {},
      toptext: {}
    }
  }

  componentDidMount() {
    let storage = firebase.storage();
    let topref = storage.ref('images/abouttop/abouttop.jpg');
    let buttomref = storage.ref('images/aboutbottom/aboutbottom.jpg');
    topref.getDownloadURL().then(
      img => {
        this.setState(
          { topImagepath: img }
        )
      }
    )
    buttomref.getDownloadURL().then(
      img => {
        this.setState(
          { buttomImagepath: img }
        )
      }
    )

    var docTopRef = db.collection("texts").doc("abouttop");

    docTopRef.get().then((doc) => {
    if (doc.exists) {
        console.log("Document data:", doc.data());;
        this.setState({toptext: doc.data()})
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
}).catch(function(error) {
    console.log("Error getting document:", error);
});

var docbuttomRef = db.collection("texts").doc("aboutbuttom");

docbuttomRef.get().then((doc) => {
if (doc.exists) {
    console.log("Document data:", doc.data());;
    this.setState({buttomtext: doc.data()})
} else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
}
}).catch(function(error) {
console.log("Error getting document:", error);
});
  }

  public render() {
    return (
      <>
        <Router history={history}>

          <div className="container, pagebg">
            <div className='AboutMain'>
              <h2 className='headerTitle'> Om Mig.</h2>
            </div>

            <div className="row contentTop">
              <div className="col-xs-12">

              </div>
            </div>

            <div className="row contentUpperMiddle">
              <div className="col-xs-0 col-sm-0 col-md-2 col-lg-2  contentUpperMiddleMargin">

              </div>
              <div className="col-xs-12 col-sm-12 col-md-3 contentUpperMiddlePhoto">
                <img className="AboutImage" src={this.state.topImagepath}></img>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-5 contentUpperMiddleText">
              {/* <div className="titlebackgroundText"> 1</div> */}
                <div className="textBlur"> 
                {this.state.toptext.text}
                    {/* Jeg er uddannet sadelmager igennem Society of Masters Saddlers i England. De 2 første år af min uddannelse, tog jeg på Capel Manor College, som er det førende uddannelses sted for sadelmager I England. Her opnåede jeg min 3 Level 2 eksamener – Sadel – hovedtøj og seletøj. Herefter arbejde jeg for forskellige sadelmager i England, hvor jeg samtidig tog Level 3 – i sadel – hovedtøj og dermed blev Qualified Saddler, herudover tog jeg et Diplom i taske design, ved London College of Fashion.
                 */}</div>
                
              </div>
              <div className="col-xs-0 col-sm-0 col-md-2 col-lg-2 contentUpperMiddleMargin">

              </div>
            </div>

            <div className="row contentLowerMiddle">
              <div className="col-xs-0 col-sm-0 col-md-2 col-lg-2 contentLowerMiddleMargin">

              </div>
              <div className="col-xs-12 col-sm-12 col-md-5 contentLowerMiddleText">
              {/* <div className="titlebackgroundText"> 1</div> */}
              <div className="textBlur">
                    {/* I 2009 startede jeg mit firma Sadelmager Kathrine Bøg Holm og servicerer forholdsvis på Fyn og Midt og Sdr. Jylland. Jeg fører selv fortrinsvis Engelske sadelmærker, men tilpasser også gerne andre sadelmærker. For mig er det vigtigste at sadlen passer hesten og ikke hvilket mærke sadlen er.

                    Løbende efteruddanner jeg mig i England og har således opnået min Level 3 i seletøj, samt påbegyndt uddannelsen som Qualified Saddle Fitter. Jeg deltager en gang om året i konkurrencer ved Sadlers Hall i London og har her vundet flere præmier.

                    Jeg er selv rytter og rider dagligt. */}
                    {this.state.buttomtext.text}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-3 contentLowerMiddlePhoto">
                <img className="AboutImage" src={this.state.buttomImagepath}></img>
              </div>
              <div className="col-xs-0 col-sm-0 col-md-2 col-lg-2 contentLowerMiddleMargin">

              </div>
            </div>

            <div className="row contentButtom">
              <div className="col-xs-12">

              </div>
            </div>
                       
          </div>
          <LatestNews></LatestNews> 
        </Router>

      </>

    );
  }
}
