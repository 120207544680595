import * as React from 'react';
import * as fb from '../fireBase/firebase'
import { NewsItem } from '../../Classes/NewsItem';
import { GalleryImage } from '../../Classes/GalleryImage';
import { Row, Col } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';

export interface ILatestNewsProps {
}

export interface ILatestNewsState {
  newz: NewsItem[],
  images: GalleryImage[];
  sl: NewsItem

}

export default class LatestNews extends React.Component<ILatestNewsProps, ILatestNewsState> {
  constructor(props: ILatestNewsProps) {
    super(props);

    this.state = {
      newz: [],
      images: [{ original: "", thumbnail: "" }],
      sl: { id: "", text: "", imageUrls: "", heading: "", date: new Date() }
    }
  }

  componentDidMount() {
    this.lookupdata();

  }
  handleTimestamp = () => {
    if (this.state.newz.length > 0) {
      const timestamp = new Date((this.state.newz[0].date['seconds'] * 1000).valueOf());
      this.setState(
        {
          sl: this.state.newz[0],
          images: JSON.parse(this.state.newz[0].imageUrls)
        }
      )

    }
  }

  lookupdata = async () => {
    let ref = fb.db.collection('news').orderBy('date', 'desc');
    let itemsarray: any = [];
    ref.get().then((docs) => {
      console.log('docs', docs);
      /* let itemsarray = docs.docs.map(doc => {doc.data();}); */
      docs.docs.map(doc => {
        let newsItem = new NewsItem(doc.id, doc.data().date, doc.data().text, doc.data().imageUrls, doc.data().heading);
        itemsarray.push(newsItem)
      });
      let imglist = [];
      const sortedList = itemsarray.sort((a, b) => a - b);
      /* const sortedList = itemsarray.sort((a, b) => new Date(a.date['seconds'] * 1000).getTime().valueOf() - new Date(b.date['seconds'] * 1000).getTime().valueOf());  */
      /*  console.log('sortls', sortedList, new Date((sortedList[0].date['seconds'] * 1000).valueOf()), new Date((sortedList[1].date['seconds'] * 1000).valueOf()), new Date((sortedList[2].date['seconds'] * 1000).valueOf())); */
      this.setState(
        {
          newz: sortedList,
          images: JSON.parse(sortedList[sortedList.length - 1].imageUrls)
        }, () => { this.handleTimestamp() }

      );
    });
  }

  public render() {
    return (
      <div className="container latestnews pagebg">
        <h3>Seneste Nyhed:</h3>
        <Row>
          <Col>
            <h5>{this.state.sl.heading}</h5>
            <h6>{new Date((this.state.sl.date['seconds'] * 1000)).toDateString()}</h6>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md="4">
            <ImageGallery  showPlayButton={false} showThumbnails={false} items={this.state.images} />
          </Col>
          <Col>
            {this.state.sl.text}
          </Col>
        </Row>
      </div>
    );
  }
}
