import * as React from 'react';
import { CardGroup, Card, ListGroup, ListGroupItem, CardDeck, Modal, Button } from 'react-bootstrap';
import { Saddle } from '../../Classes/Saddle';
import * as fb from '../fireBase/firebase'

export interface IShopProps {
}

export interface IShopState {
  saddles: any[],
  saddle: Saddle,
  setShow: boolean,
  currentImgUrl: string
}

const db = fb.db;

export default class Shop extends React.Component<IShopProps, IShopState> {
  constructor(props: IShopProps) {
    super(props);

    this.state = {
      saddles: [],
      saddle: {id:"", name: "", size: "", brand: "", price: "", color: "", info: "", description: "", type: "", age: "", downloadURLs: [] },
      setShow: false,
      currentImgUrl: ""
    }
  }

  componentDidMount() {
    this.lookupdata();

  }

  handleClose = () => {this.setState({setShow: false})};
  handleShow = () => {this.setState({setShow: true})};

  handleImageModal = (saddleIndex, ImgIndex) => {
    this.setState({
      currentImgUrl: this.state.saddles[saddleIndex].downloadURLs[ImgIndex]
    }, this.handleShow);

  }

  lookupdata = () => {
    let ref = db.collection('saddles');

    ref.get().then((docs) => {
      console.log('docs', docs);
      let itemsarray = docs.docs.map(doc => doc.data());

      console.log('saddlesarray ', itemsarray);
      this.setState(
        {
          saddles: itemsarray
        }
      )
    });
  }

  public render() {
    return (
      <div className="container topSpacer">
        <div className='ShopMain'>
         <h2 className='headerTitle'>Salg af Sadler</h2>
         </div>
        <CardDeck>
        {this.state.saddles.map((s, i) => {
          
          return (
          <Card key={i}>
            <div className="row">
            {s.downloadURLs.map((dlu, ii) => {
              console.log('dlu', dlu);
              return(
                <div key={ii} className="col-4">
              <Card.Img onClick={() => this.handleImageModal(i, ii)}  src={dlu} /> 
              </div>
                  )
              })}
              </div>
          <Card.Body>
            <Card.Title>{s.name}</Card.Title>
            <Card.Subtitle>
              {s.info}
            </Card.Subtitle>
            <Card.Text>
              {s.description}
            </Card.Text>
            
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              <span className="cardLabel">
                Størrelse
              </span>
              <Card.Text>
                {s.size}
              </Card.Text>
            </ListGroupItem>
            <ListGroupItem>
              <span className="cardLabel">
                Farve
              </span>
              <Card.Text>
                {s.color}
              </Card.Text></ListGroupItem>
            <ListGroupItem>
            <span className="cardLabel">
                Mærke
              </span>
              <Card.Text>
                {s.brand}
              </Card.Text>
            </ListGroupItem>
            <ListGroupItem>
            <span className="cardLabel">
                Alder
              </span>
              <Card.Text>
                {s.age}
              </Card.Text>
            </ListGroupItem>
            <ListGroupItem>
            <span className="cardLabel">
                Sadeltype
              </span>
              <Card.Text>
                {s.type}
              </Card.Text>
            </ListGroupItem>
            <ListGroupItem>
            <span className="cardLabel">
                Pris
              </span>
              <Card.Subtitle>
                {s.price} kr
              </Card.Subtitle>
            </ListGroupItem>
          </ListGroup>
        </Card>)
        })}
        </CardDeck>

        <Modal show={this.state.setShow} onHide={this.handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body><img className="shopimage" src={this.state.currentImgUrl}></img></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Luk
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
  }
}
