import 'firebase/auth';

const config = {
  apiKey: "AIzaSyCS4Ttqvw4lsgl-HiU99lgfpsSoHMyVuTo",
  authDomain: "sadelmagerfyn-051281.firebaseapp.com",
  databaseURL: "https://sadelmagerfyn-051281.firebaseio.com",
  projectId: "sadelmagerfyn-051281",
  storageBucket: "sadelmagerfyn-051281.appspot.com",
  messagingSenderId: "144528501200",
  appId: "1:144528501200:web:c8ec87aa798e1c67",
  measurementId: "G-BE0KP2H7DG"
};

  export {
    config
  }